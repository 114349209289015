import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68')
];

export const server_loads = [0,2,8,3];

export const dictionary = {
		"/(app)": [~11,[2]],
		"/(app)/account/api": [~15,[2,4]],
		"/(app)/account/billing": [16,[2,4]],
		"/(app)/account/integrations": [~17,[2,4]],
		"/(app)/account/notifications": [18,[2,4]],
		"/(app)/account/organization": [~19,[2,4]],
		"/(app)/account/profile": [20,[2,4]],
		"/(app)/account/update-email": [21,[2,4,5]],
		"/(app)/account/update-password": [22,[2,4,6]],
		"/(app)/admin": [23,[2,7]],
		"/(app)/admin/api": [~24,[2,7]],
		"/(app)/admin/bulk-add": [~25,[2,7]],
		"/(app)/admin/new-site": [~26,[2,7]],
		"/(app)/admin/oauth": [27,[2,7]],
		"/(app)/admin/onboarding": [~28,[2,7]],
		"/(app)/admin/sites": [~29,[2,7]],
		"/(app)/admin/users": [30,[2,7]],
		"/(app)/admin/users/[id]": [31,[2,7]],
		"/(app)/booking-management": [~32,[2]],
		"/(checkout)/checkout-public": [~66,[10]],
		"/(app)/checkout": [~33,[2]],
		"/(app)/classes": [~34,[2]],
		"/(app)/clients": [~35,[2]],
		"/(app)/dashboard": [~36,[2]],
		"/(app)/dashboard/saved": [~39,[2]],
		"/(app)/dashboard/saved/[id]": [~40,[2]],
		"/(app)/dashboard/[site_id]": [~37,[2,8]],
		"/(app)/dashboard/[site_id]/[location_id]": [~38,[2,8]],
		"/(app)/exports": [41,[2]],
		"/(app)/exports/cancellations": [42,[2]],
		"/(app)/exports/clients-with-balances": [43,[2]],
		"/(app)/exports/historical-snapshot": [44,[2]],
		"/(app)/exports/leads-without-purchase": [45,[2]],
		"/(app)/exports/visits-by-class": [46,[2]],
		"/(auth)/forgot-password": [63,[9]],
		"/(app)/late-cancels": [~47,[2]],
		"/(app)/logging-in": [48],
		"/(auth)/login": [64,[9]],
		"/(app)/logout": [49,[2]],
		"/(app)/opportunities": [~50,[2]],
		"/(app)/organizations": [51,[2]],
		"/ping": [67],
		"/(app)/playground": [52,[2]],
		"/(app)/pricing": [~53,[2]],
		"/(app)/products": [~54,[2]],
		"/(auth)/register": [65,[9]],
		"/(app)/scheduler": [~55,[2]],
		"/sentry-example": [68],
		"/(app)/settings": [~56,[2]],
		"/(app)/snapshot": [~57,[2]],
		"/(app)/snapshot/[site_id]": [~58,[2]],
		"/(app)/snapshot/[site_id]/[location_id]": [~59,[2]],
		"/(app)/staff": [~60,[2]],
		"/(app)/stripe/success": [~61,[2]],
		"/(app)/ui": [62,[2]],
		"/(app)/[org_id=integer]": [12,[2,3]],
		"/(app)/[org_id=integer]/clients": [~13,[2,3]],
		"/(app)/[org_id=integer]/clients/[id]": [~14,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';